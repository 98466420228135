import { render, staticRenderFns } from "./Checkout.vue?vue&type=template&id=763bde3e"
import script from "./Checkout.vue?vue&type=script&lang=js"
export * from "./Checkout.vue?vue&type=script&lang=js"
import style0 from "./Checkout.vue?vue&type=style&index=0&id=763bde3e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QList from 'quasar/src/components/item/QList.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QStepper,QStep,QStepperNavigation,QBtn,QIcon,QItem,QColor,QList,QRadio,QCheckbox});
